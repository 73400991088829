













































































































import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { IDisplayedSector, IRadiatingCircle } from '@/components/Map/types'
import { ISentry } from '@/store/modules/sentries/types'
import { capitalize } from '@/utils/utils'
import { EModel, IRfSensor } from '@/store/modules/rf_sensors/types'

export default Vue.extend({
  name: 'CoverageWidget',
  data: () => ({
    zoneTooltips: {
      alert:
        'Detections will trigger alerts when entering an alert zone, depending on site and alert settings.',
      disrupt:
        'Detections within a disrupt zone will trigger cannons to power on if "auto disrupt" is enabled.',
      label: 'Label zones are only for labelling purposes.'
    }
  }),
  computed: {
    ...mapState('users', ['user']),
    ...mapState('maps', ['activeMapLayers', 'mapLayerID']),
    ...mapState('zones', ['visibleZoneType', 'alertZoneFillPattern', 'zoneTypes']),
    ...mapState({
      mgrsEnabled: 'mgrsEnabled',
      milsEnabled: 'milsEnabled',
      radiatingCircleData: 'radiatingCircle',
      displayedSectors: 'displayedSectors'
    }),
    ...mapGetters(['displayedSectorsInSite']),
    ...mapGetters('zones', ['zonesList']),
    ...mapGetters('sensors', [
      'radarsLength',
      'cannonsLength'
    ]),
    ...mapGetters('cameras', ['camerasLength']),
    ...mapGetters('sites', ['activeSite', 'activeSiteId']),
    ...mapGetters('sentries', ['sentriesInSite']),
    ...mapGetters('rf_sensors', ['rfSensorsLength', 'rfSensorsSet', 'dsxSensorsLength']),
    titleZoneType(): string[] {
      return this.zoneTypes.map(type => capitalize(type))
    },
    mgrsModel: {
      get(): boolean {
        return this.mgrsEnabled
      },
      set(val): void {
        this.$store.commit('setMgrs', val)
        this.$emit('updated:settings')
      }
    },
    milsModel: {
      get(): boolean {
        return this.milsEnabled
      },
      set(val): void {
        this.$store.commit('setMils', val)
        this.$emit('updated:settings')
      }
    },
    visibleZones: {
      get() {
        return this.visibleZoneType
      },
      set(values) {
        this.$store.commit('zones/VISIBLE_ZONE_TYPES', values)
        this.$emit('updated:settings')
      }
    },
    displayTextFill: {
      get() {
        return this.alertZoneFillPattern
      },
      set(value) {
        this.$store.commit('zones/ALERT_ZONE_FILL_PATTERN', value)
        this.$emit('updated:settings')
      }
    },
    visibleDisplayedSectors: {
      get(): IDisplayedSector[] {
        return this.displayedSectors[this.activeSiteId]
      },
      set(data): void {
        let values = this.availableSectors.map(s => s.value)
        let cleanedData = Object.values(data).filter(d => values.includes(d.value))

        this.$store.commit('setDisplayedSectorsBySiteId', {
          site_id: this.activeSiteId,
          data: cleanedData
        })
        this.$emit('updated:settings')
      }
    },
    disableDisplaySensorSector(): boolean {
      return this.availableSectors.length === 0
    },
    availableSectors(): IDisplayedSector[] {
      const sectors = []
      if (this.rfSensorsLength > 0) {
        if (Object.values(this.rfSensorsSet).some((rf: IRfSensor) => rf.model === EModel.RfZeroString))
          sectors.push({ name: 'RfZero', value: EModel.RfZeroString })
        if (Object.values(this.rfSensorsSet).some((rf: IRfSensor) => rf.model === EModel.RfPatrolString))
          sectors.push({ name: 'RfPatrol', value: EModel.RfPatrolString })
        const intersectionRfSensors = Object.values(this.rfSensorsSet).filter(
          (rf: IRfSensor) => rf.model === EModel.RfOneString || rf.model === EModel.RfTwoString
        )
        if (intersectionRfSensors.length > 0)
          sectors.push({ name: 'RfOne', value: 'rf_one' })
        if (intersectionRfSensors.length > 1)
          sectors.push({ name: 'RF Intersection', value: 'rf_intersection' })
      }
      if (this.dsxSensorsLength > 0)
        sectors.push({ name: 'DroneSentry-X', value: 'dronesentryx' })
      if (this.radarsLength > 0)
        sectors.push({ name: 'RadarZero', value: 'radar' })
      if (this.cannonsLength > 0)
        sectors.push({ name: 'DroneCannon', value: 'cannon' })
      if (this.camerasLength > 0)
        sectors.push({ name: 'DroneOpt', value: 'cameras' })
      return [...sectors]
    },
    mapLayer: {
      get(): string[] {
        return this.offline
          ? ['ESRI World Imagery Satellite', 'ESRI World Imagery Topology']
          : this.activeMapLayers
      },
      set(v): void {
        this.setActiveMapLayers(v)
      }
    },
    radiatingCircle: {
      get(): IRadiatingCircle {
        return this.radiatingCircleData
      },
      set(data): void {
        this.setRadiatingCircle(data)
        this.$emit('updated:settings')
      }
    },
    sentries(): ISentry[] {
      return this.sentriesInSite(this.activeSiteId)
    },

    radiatingCircles(): IRadiatingCircle[] {
      return (
        this.activeSite && [
          { name: 'None', coordinates: null },
          {
            name: `Site (${this.activeSite.name})`,
            coordinates: [this.activeSite.latitude, this.activeSite.longitude]
          },
          ...this.sentries
            .filter(
              s =>
                s.latitude != this.activeSite.latitude &&
                s.longitude != this.activeSite.longitude
            )
            .map(s => {
              return {
                name: `Installation (${s.name})`,
                coordinates: [s.latitude, s.longitude]
              }
            })
        ]
      )
    },
    offline: {
      get(): boolean {
        return this.$store.state.offline
      },
      set(v): void {
        return this.$store.commit('setOffline', v)
      }
    }
  },
  methods: {
    ...mapActions('maps', ['setActiveMapLayers']),
    ...mapActions('users', ['UPDATE_USER']),
    ...mapActions(['setRadiatingCircle']),
    async initializeActiveMapLayers(): Promise<void> {
      await this.setActiveMapLayers(this.user.settings.mapLayers)
    },
    onChange(zoneTypes, type): void {
      if (zoneTypes.includes(type)) {
        const noZone = this.zonesList.findIndex(z => z.zone_type === type) < 0
        if (noZone) this.$bus.$emit('SNACKBAR/EMPTY_ZONE_TYPE_ENABLED', type)
      }
    }
  },
  mounted(): void {
    this.mgrs = this.mgrsEnabled
    this.mils = this.milsEnabled
  },
  watch: {
    mapLayer: {
      handler: async function(v): Promise<void> {
        try {
          let user = Object.assign({}, this.user)
          user.settings.mapLayers = v
          // check if current map is in map layers list
          const inList = user.settings.mapLayers.find(layer => {
            return layer === user.settings.map
          })
          if (!inList) {
            user.settings.map = this.activeMapLayers[
              this.mapLayerID % this.activeMapLayers.length
              ]
          }
          await this.UPDATE_USER(user)
        } catch (err) {}
      }
    }
  }
})
